
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fontawesome';
import App from './App';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_ADMIN_AUTH_REGION,
    userPoolId: process.env.REACT_APP_ADMIN_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_ADMIN_USERPOOLID_CLIENT,
    // custom_header: async () => { 
    //   return {
    //     Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    //   };
    // }
  },
  API: {
    endpoints: [
      {
        name: "lostinthewild",
        endpoint: process.env.REACT_APP_ADMIN_API_URL,
        region: process.env.REACT_APP_ADMIN_API_REGION,
      },
    ]
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
);
